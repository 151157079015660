import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageBody from '../components/PageBody'
import FeaturedPosts from '../components/FeaturedPosts'
import SEO from '../components/SEO'

const PageTemplate = ({ data, pageContext }) => {
  const { title, slug, body } = data.contentfulPage
  const postNode = data.contentfulPage
  const { featuredPosts } = pageContext
  const isCustomPage = slug.includes('optiflow/support')
    || slug.includes('optiflow/privacy-policy')
    || slug.includes('optismed/support')
    || slug.includes('optismed/privacy-policy');

  return (
    <Layout>
      <Helmet>
        <title>{`${title} - ${config.siteTitle}`}</title>
        <body className={`optimusconsult-${slug.replace(/\//, '-')}`} />
      </Helmet>
      <SEO pagePath={slug} postNode={postNode} pageSEO />

      <Container>
        {isCustomPage || <FeaturedPosts posts={featuredPosts} />}
        <PageBody body={body} />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!, $locale: String!) {
    contentfulPage(
      node_locale: { eq: $locale }
      slug: { eq: $slug }
    ) {
      title
      slug
      metaDescription {
        internal {
          content
        }
      }
      body {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 320)
        }
      }
    }
  }
`

export default PageTemplate
